/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */

import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-800  dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl text-center">
        <a
          href="#"
          className="flex justify-center items-center text-2xl font-semibold text-white dark:text-white"
        >
          <img src="../assets/favicon.png" alt="version1news" />
          Version1News
        </a>

        <ul className="flex flex-wrap justify-center items-center m-2 text-white dark:text-white">
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Premium
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6 ">
              Campaigns
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Blog
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Affiliate Program
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              FAQs
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Contact
            </a>
          </li>
        </ul>
        <span className="text-sm text-white sm:text-center dark:text-gray-400">
          Term of Use .{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            Privacy Policy
          </a>
        </span>
        <div className="text-sm text-white sm:text-center dark:text-gray-400">
          2023 © Version 1 News
        </div>
      </div>
    </footer>
  );
};

export default Footer;
