import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto mt-8 p-4">
      <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">
        <strong>Effective Date:</strong> November 10, 2024
      </p>

      <p>
        Universal News Group is the world leader in news-based entertainment. We
        are the home for news’s greatest artists, innovators, and entrepreneurs.
        As digital technology refashions the world, our unmatched commitment to
        lead by developing new services, platforms, and business models for the
        delivery of news and related content empowers innovators and allows new
        commercial and artistic opportunities to flourish.
      </p>

      <p>
        As part of this mission, Universal News Group operates websites,
        applications, stores, and other services (which we refer to in this
        privacy policy as "Apps") to help our artists stay connected with fans
        in new and exciting ways.
      </p>

      <h3 className="text-2xl font-bold mt-6">WHO WE ARE</h3>
      <p>
        Apps that link to this privacy policy are operated by Universal News
        Investments, Inc., located at 2124 Colorado Ave, Santa Monica, CA 90404.
        As used in this policy, "UMG," "we," and "our" refers to this entity,
        who acts as a data controller and is responsible for the processing of
        personal data by the Apps under applicable privacy and data protection
        laws.
      </p>

      {/* ... Additional sections of the privacy policy ... */}

      <h3 className="text-2xl font-bold mt-6">COOKIES</h3>
      <p>
        Our Apps use cookies and other similar technologies to improve your
        experience and support targeted advertising. For the purpose of this
        Cookies Policy, cookies, and other similar technologies (like scripts,
        plug-ins, tags, device fingerprints, Local Stored Objects, beacons, and
        pixels) are all referred to as “cookies.”
      </p>

      <p>
        You can find more information about the cookies and other tracking
        technologies we use, the purposes for which we use them, and make
        certain choices about cookies through the cookie choices tools available
        on our Apps.
      </p>

      <p>
        Most internet browsers are automatically set up to accept cookies.
        However, if you want to refuse or delete any cookies (or similar
        technologies), please refer to the help and support area on your
        internet browser for instructions on how to block or delete cookies.
        Please note you may not be able to take advantage of all the features of
        our App, including certain personalized features, if you delete or
        disable cookies.
      </p>

      <p>
        For more information on managing cookies, please go to{" "}
        <a href="http://www.allaboutcookies.org" className="text-blue-500">
          www.allaboutcookies.org
        </a>
        , or visit{" "}
        <a href="http://www.youronlinechoices.eu" className="text-blue-500">
          www.youronlinechoices.eu
        </a>{" "}
        which has further information about behavioral advertising and online
        privacy.
      </p>
      <h3 className="text-2xl font-bold mt-6">
        HOW YOUR INFORMATION MAY BE USED
      </h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Respond to requests and/or queries, including customer support queries
        </li>
        <li>
          Fulfill orders, process payments, and provide order-related updates
        </li>
        <li>
          Send requested marketing communications via email, SMS, or direct
          messages
        </li>
        <li>
          Advertise our products or the products of third parties that we
          believe may be of interest
        </li>
        <li>
          Create segments of users with similar features for ad targeting or
          seeding lookalike audiences
        </li>
        <li>Operate, measure, and audit online advertisements</li>
        <li>
          Build user profiles to assist us in understanding your interests,
          create marketing or advertising segments, and predict likely sales,
          marketing, or advertising outcomes
        </li>
        <li>
          Train AI or machine learning models that analyze or predict sales,
          marketing, or advertising outcomes
        </li>
        <li>Personalize and improve our Apps, products, and services</li>
        <li>Administer promotions, contests, or sweepstakes</li>
        <li>
          Operate and administer our Apps, including troubleshooting, data
          analysis, testing, research, statistics, and other internal operations
        </li>
        <li>
          Manage your access to our Apps, including notifying you of changes to
          the Apps
        </li>
        <li>Analyze usage of our Apps</li>
        <li>Secure our Apps and prevent fraud or other illegal activities</li>
        <li>Resolve disputes and/or enforce our agreements</li>
        <li>Comply with legal and regulatory obligations</li>
      </ul>

      <h3 className="text-2xl font-bold mt-6">
        HOW YOUR INFORMATION MAY BE SHARED
      </h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Our affiliates and joint venture partners that we have now or may have
          in the future
        </li>
        <li>
          Our business partners, suppliers, agents, and sub-contractors who
          provide services on our behalf
        </li>
        <li>Relevant UMG artists and/or their representatives</li>
        <li>
          With other users where you leave comments or otherwise contribute
          content to an App
        </li>
        <li>Advertisers, advertising networks and social networks</li>
        <li>
          As part of an actual or potential corporate sale, merger, or
          acquisition, or other transfer of all or part of UMG
        </li>
        <li>
          Pursuant to a subpoena, court order, governmental inquiry, or other
          legal process or as otherwise required by law, to protect our rights
          or the rights of third parties, or to protect the safety or security
          of any person or entity
        </li>
        <li>
          With your consent or as otherwise disclosed at the time of data
          collection or sharing
        </li>
      </ul>
      <h3 className="text-2xl font-bold mt-6">
        STORAGE AND RETENTION OF INFORMATION
      </h3>
      <p className="mb-4">
        We will retain the information we collect in accordance with applicable
        laws and as reasonably necessary for our processing purposes set out
        under this privacy policy, including resolution of disputes, enforcement
        of agreements, securing the Apps, and to comply with our legal
        obligations. When personal data is no longer necessary for our purposes,
        we will delete or deidentify the data.
      </p>
      <p className="mb-4">
        We will also take steps to ensure that the information we collect is
        treated securely and in accordance with this Privacy Policy. We have put
        in place technical and organizational procedures designed to safeguard
        the information we collect.
      </p>
      <p className="mb-4">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we will do our best to protect the
        information we collect, we cannot guarantee its security.
      </p>

      <h3 className="text-2xl font-bold mt-6">DIGITAL ADVERTISING</h3>
      <p className="mb-4">
        We use third-party partners to enable targeted advertising on
        third-party social media platforms, websites, and mobile apps. These
        companies collect information through our Apps to target users and serve
        ads. Some of these ads may be personalized, meaning that these ads are
        intended to be relevant to you based on information the companies
        collect or receive about your use of our App and third-party sites and
        apps over time, including through the use of cookies and other tracking
        technologies.
      </p>
      <p className="mb-4">
        Visit the DAA Webchoices tool at{" "}
        <a href="https://www.aboutads.info">www.aboutads.info</a> to learn more
        about targeted advertising and how to opt out of targeted advertising by
        companies participating in the DAA self-regulatory program. If you
        delete your cookies or use a different browser or mobile device, you may
        need to renew your opt-out choices exercised through the DAA Webchoices
        tool.
      </p>
      <p className="mb-4">
        To opt-out of targeted advertising in mobile apps by companies
        participating in the DAA’s AppChoices program, you can download that app
        onto your device from{" "}
        <a href="https://youradchoices.com/appchoices">
          https://youradchoices.com/appchoices
        </a>
        . You can also limit data collection and use by third parties for
        targeted advertising in your mobile device settings, where available.
      </p>
      <p className="mb-4">
        Note that electing to opt out will not stop advertising from appearing
        in your browser or applications. It may make the ads you see less
        relevant to your interests.
      </p>
      <h3 className="text-2xl font-bold mt-6">CHILDREN</h3>
      <p className="mb-4">
        Our apps are not directed toward children (as defined by local law) nor
        does UMG knowingly collect information from children (as defined by
        local law) without parental consent except where in compliance with
        applicable laws.
      </p>

      <h3 className="text-2xl font-bold mt-6">YOUR RIGHTS</h3>
      <p className="mb-4">
        You may have certain rights regarding our processing of personal data
        about you. These rights may include access to, rectification, erasure,
        and/or portability of your personal data. You can exercise these rights
        by contacting us as described in the “Contact” section. We may take
        steps to verify your identity before completing your request. Our
        verification measures vary based on the risk to the individual and may
        involve confirming access to the relevant email account, providing a
        government-issued ID or other identification documents, or providing
        other account details or information that indicates a valid request.
      </p>
      <p className="mb-4">
        You may also be entitled, in accordance with applicable law, to lodge a
        complaint with a supervisory authority if you consider that our
        processing of your personal data infringes applicable law.
      </p>
      <p className="mb-4">
        Specific information regarding rights related to data processing of
        European data and personal data about residents of California, Colorado,
        Virginia, Connecticut, and Utah can be found on our US State and
        European Rights page.
      </p>

      <h3 className="text-2xl font-bold mt-6">CHANGES TO OUR PRIVACY POLICY</h3>
      <p className="mb-4">
        Any material changes we may make to our privacy policy in the future
        will be posted here. Please check back frequently to see any updates or
        changes to our privacy policy.
      </p>

      <h3 className="text-2xl font-bold mt-6">COOKIES</h3>
      <p className="mb-4">
        Our Apps use cookies and other similar technologies to improve your
        experience and support targeted advertising. For the purpose of this
        Cookies Policy, cookies, and other similar technologies (like scripts,
        plug-ins, tags, device fingerprints, Local Stored Objects, beacons, and
        pixels) are all referred to as “cookies.”
      </p>
      <p className="mb-4">
        You can find more information about the cookies and other tracking
        technologies we use, the purposes for which we use them, and make
        certain choices about cookies through the cookie choices tools available
        on our Apps.
      </p>
      <p className="mb-4">
        Most internet browsers are automatically set up to accept cookies.
        However, if you want to refuse or delete any cookies (or similar
        technologies), please refer to the help and support area on your
        internet browser for instructions on how to block or delete cookies.
        Please note you may not be able to take advantage of all the features of
        our App, including certain personalized features, if you delete or
        disable cookies.
      </p>
      <p className="mb-4">
        For more information on managing cookies, please go to{" "}
        <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>,
        or visit{" "}
        <a href="https://www.youronlinechoices.eu">www.youronlinechoices.eu</a>{" "}
        which has further information about behavioral advertising and online
        privacy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
