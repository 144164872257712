import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({
  setSelected,
  navbarOpen,
  setNavbarOpen,
  categories,
  selected,
  currentTime,
}) => {
  console.log("navbar");
  return (
    <nav className="sticky top-0 flex flex-wrap items-center justify-between px-2 py-3 bg-gradient-to-r from-gray-700 via-gray-500 to-pink-300 mb-3 z-10">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div
            onClick={() => setSelected("News")}
            className="navbar-title text-white"
          >
            <img src="/assets/favicon.png" alt="companyLogo" />
            Version1News
          </div>
          <button
            className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center" +
            (navbarOpen ? " flex" : " hidden")
          }
          id="example-navbar-danger"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {categories.map((category, i) => (
              <li
                key={i}
                className="nav-item"
                onClick={() => {
                  setSelected(category.name);
                }}
              >
                <div
                  className={`cursor-pointer px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  hover:opacity-75    ${
                    selected === category.name ? "text-gray-800" : "text-white"
                  }`}
                >
                  <FontAwesomeIcon icon={category.icon} className="news-icon" />
                  <span className="ml-2"> {category.name}</span>
                </div>
              </li>
            ))}
          </ul>
          {!navbarOpen && <div className="navbar-time">{currentTime}</div>}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
