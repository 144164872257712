import React from "react";

const SingleNews = ({ content, description, url, urlToImage, selected }) => {
  console.log("SingleNews");
  return (
    <>
      <img
        className="w-full"
        src={urlToImage ?? "assets/emptyImg.jpeg"}
        alt="Mountain"
        loading="lazy"
        onError={(event) => {
          event.target.src = "assets/emptyImg.jpeg";
          event.onerror = null;
        }}
      />
      <div className="px-6 py-4">
        <div
          className="font-bold text-xl mb-2"
          dangerouslySetInnerHTML={{
            __html: content?.replace(/\[.*chars\]/, "") ?? content ?? "",
          }}
        />
        <p className="text-gray-700 text-base">{description}</p>
        <a className="font-bold text-l" href={url} target="_blank">
          read more....
        </a>
      </div>
      <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          #{selected}
        </span>
      </div>
    </>
  );
};

export default SingleNews;
