import "../App.css";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  faBriefcase,
  faFootball,
  faLaptop,
  faGavel,
  faHeartbeat,
  faFlask,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Components/Footer";
import Skeleton from "../Components/Skeleton";
import SingleNews from "../Components/SingleNews";
import Navbar from "../Components/Navbar";

const emptyNews = "[Removed]";

const fetchNews = async (selected) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEWS_API_URL}/${selected}`,
    {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    }
  );
  const result = await response.json();
  return result.filter((a) => a.content && a.content !== emptyNews);
};
function News() {
  console.log("news");
  const [currentTime, setCurrentTime] = useState("");
  const [selected, setSelected] = useState("News");
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { data: news, isLoading } = useQuery(["news", selected], () =>
    fetchNews(selected)
  );

  const categories = [
    { name: "Business", icon: faBriefcase },
    { name: "Sports", icon: faFootball },
    { name: "Technology", icon: faLaptop },
    { name: "Politics", icon: faGavel },
    { name: "Health", icon: faHeartbeat },
    { name: "Science", icon: faFlask },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedDateTime = now.toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      setCurrentTime(formattedDateTime);
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [selected]);
  return (
    <div>
      <Navbar
        setSelected={setSelected}
        navbarOpen={navbarOpen}
        setNavbarOpen={setNavbarOpen}
        categories={categories}
        selected={selected}
        currentTime={currentTime}
      />
      <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
        {isLoading
          ? Array.from({ length: 9 }).map((_, index) => (
              <Skeleton key={index} />
            ))
          : news.map((a, i) => (
              <div
                key={i}
                className="max-w-sm mx-auto rounded overflow-hidden shadow-lg"
              >
                <SingleNews
                  content={a.content}
                  description={a.description}
                  url={a.url}
                  urlToImage={a.urlToImage}
                  selected={selected}
                />
              </div>
            ))}
      </div>
      <Footer />
      {/* <PrivacyPolicyPopup /> */}
    </div>
  );
}

export default News;
